<template>
  <div class="checkbox" :class="{ checkbox_checked: isChecked, checkbox_disabled: disabled }">
    <div class="checkbox__wrap">
      <svg
        v-show="isChecked"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 6L6.5 11L4 8.72727"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input
        type="checkbox"
        :id="id || `checkbox_${_uid}`"
        v-model="inputVal"
        :value="inputValue"
        :disabled="disabled"
        @input="$emit('change')"
      />
    </div>
    <div class="checkbox__text">
      <label
        :for="`checkbox_${_uid}`"
        class="checkbox__label"
        v-html="label"
        ref="triggerLabel"
      ></label>
      <div class="checkbox__error" v-if="isError" v-html="errorText"></div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "Checkbox",
  props: {
    id: String,
    value: [Boolean, Array],
    label: String,
    inputValue: [String, Number],
    disabled: Boolean,
    errorText: String,
    isError: {type: Boolean, default: false},
  },
  data: () => ({
   
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isChecked() {
      return typeof this.inputVal !== "boolean"
        ? this.inputVal.includes(this.inputValue)
        : this.inputVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.triggerLabel.querySelector("a")) {
      this.$refs.triggerLabel
        .querySelector("a")
        .addEventListener("click", function (event) {
          event.preventDefault();
          if(this.getAttribute('href') === '#openPolicy') {
            bus.$emit('openPolicy', true)
          }
        });
    }
    })
    
  },
  watch: {
    disabled() {
      if (this.disabled) {
        this.inputVal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__wrap {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 2px solid #f6cce2;
    border-radius: 4px;
    flex-shrink: 0;
    margin-top: 2px;

    &:hover {
      box-shadow: 0px 0px 3px 1px rgba(208, 0, 111, 0.2);
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1000;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 11px;
  }

  &__label {
    margin-top: 1px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3c4242;
    cursor: pointer;
  }

  &__error {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #cb4050;
  }
}

.checkbox_checked {
  .checkbox {
    &__wrap {
      background-color: #830051;
      border-color: #830051;
    }
  }
}

.checkbox_disabled {
  opacity: 0.8;
  pointer-events: none;
}
</style>

<style lang="scss">
.checkbox__label {
  & > a {
    color: #830051;
  }
}

.v-select .checkbox__label {
  white-space: pre-wrap;
}
</style>